import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isAuthenticated } from '../redux/actions';
import { useCookies } from 'react-cookie';
import { Modal, Button } from 'react-bootstrap';
import { logoutFunction } from '../redux/api';


function Signout({ show, setShow }) {
	const [_, setCookie, removeCookie] = useCookies(['userId', 'userToken', 'restaurantId']);
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(logoutFunction())
    };

    return (
        <div className='logout-screen-modal'>
            <Modal className='logout-pop' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Logout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='logut-content'>
               {/* <img src={logoutpower}/> */}
                <p>Are you sure you would like to logout of your account?</p>
               </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={logout}>
                    Logout
                </Button>
            </Modal.Footer>
        </Modal>
        </div>
    );
}

export default Signout;
