import axios from "axios";

const routePath = process.env.REACT_APP_DEV_MODE;

const axiosInstance = axios.create({
  baseURL: `${routePath}/apis`,
});

// Add interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response}, 
  (error) => {
    if (error.response && error.response.status === 401) {
      console.warn("Unauthorized! Clearing storage and redirecting...");
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload(); // Redirect user to login page
    }
    return Promise.reject(error); // Return error for further handling
  }
);

export default axiosInstance;
