import React, { useRef, useState } from 'react';
import QRCode from "react-qr-code";
import logo from '../../../assets/images/logo-n.png'


function Qr({teamdetails}) {
    const componentRef = useRef();
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
      navigator.clipboard.writeText(teamdetails.team_code).then(
        function () {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        },
        function (err) {
          console.error("Failed to copy: ", err);
        }
      );
    };

    
    return ( 
        <>
         <div className="d-flex flex-column justify-content-center align-items-center " >
                     <div className="qr qr-seperate" ref={componentRef} style={{ width: '100%', }}>
                    <div className='b-logo'>
                      <img src={logo} />
                    </div>
                      
                        <div className="groupname">
                        <div className="groupicon qr-team-logo">
                          {/* <img src={teamdetails.team_logo || noimage} width='150px' /> */}
                          <div className="aboutgrp d-flex">
                            <p>{teamdetails.team_name}</p>

                            <div className="other-info text-start">
                              <strong>
                                {teamdetails.age_group||teamdetails.age_group || 'N/A'}
                              </strong>
                            </div>
                          </div>

                        </div>

                      </div>
                      <div className='qr-wraps'>
                      <div  className="qr-sep" style={{ height: "auto", width: "100%" }}>
                          <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={`https://giuteamconnect.com/app?type=team&&code=${teamdetails.team_code}`}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                  
                      </div>
                   
                        <div className='qr-copy'>
                        <div className='qr-box'>  <p className="qr-code-text">{teamdetails.team_code}</p></div>
                        <div className='copy-code' onClick={copyToClipboard}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.26801 2.8125C5.26801 2.088 5.85601 1.5 6.58051 1.5H15.1875C15.912 1.5 16.5 2.088 16.5 2.8125V11.436C16.5 11.7841 16.3617 12.1179 16.1156 12.3641C15.8694 12.6102 15.5356 12.7485 15.1875 12.7485H6.58051C6.23241 12.7485 5.89857 12.6102 5.65243 12.3641C5.40629 12.1179 5.26801 11.7841 5.26801 11.436V2.8125ZM6.58051 2.625C6.53078 2.625 6.48309 2.64475 6.44792 2.67992C6.41276 2.71508 6.39301 2.76277 6.39301 2.8125V11.436C6.39301 11.5402 6.47701 11.6235 6.58051 11.6235H15.1875C15.2372 11.6235 15.2849 11.6037 15.3201 11.5686C15.3553 11.5334 15.375 11.4857 15.375 11.436V2.8125C15.375 2.76277 15.3553 2.71508 15.3201 2.67992C15.2849 2.64475 15.2372 2.625 15.1875 2.625H6.58051Z" fill="white"/>
                          <path d="M1.49625 8.06175C1.49615 7.88933 1.53002 7.71858 1.59594 7.55925C1.66185 7.39993 1.75852 7.25515 1.8804 7.13319C2.00229 7.01124 2.14701 6.91449 2.3063 6.84848C2.46559 6.78248 2.63632 6.7485 2.80875 6.7485H3.9375C4.08668 6.7485 4.22976 6.80777 4.33524 6.91326C4.44073 7.01875 4.5 7.16182 4.5 7.311C4.5 7.46019 4.44073 7.60326 4.33524 7.70875C4.22976 7.81424 4.08668 7.8735 3.9375 7.8735H2.80875C2.75902 7.8735 2.71133 7.89326 2.67616 7.92842C2.641 7.96359 2.62125 8.01128 2.62125 8.061L2.625 15.1875C2.625 15.291 2.70825 15.375 2.8125 15.375H9.9375C9.98722 15.375 10.0349 15.3553 10.0701 15.3201C10.1052 15.2849 10.125 15.2372 10.125 15.1875V14.055C10.125 13.9058 10.1843 13.7627 10.2897 13.6573C10.3952 13.5518 10.5383 13.4925 10.6875 13.4925C10.8367 13.4925 10.9798 13.5518 11.0852 13.6573C11.1907 13.7627 11.25 13.9058 11.25 14.055V15.1875C11.25 15.5356 11.1117 15.8694 10.8656 16.1156C10.6194 16.3617 10.2856 16.5 9.9375 16.5H2.8125C2.4644 16.5 2.13056 16.3617 1.88442 16.1156C1.63828 15.8694 1.5 15.5356 1.5 15.1875L1.49625 8.06175Z" fill="white"/>
                          </svg>
                        </div>
                        </div>
                      </div>
                    
                     
                     </div>
        </>
     );
}

export default Qr;