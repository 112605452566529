import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';

const LeftNav = ({ setCollapsed, collapsed, setShow }) => {
    var role = localStorage.getItem("role");
    const selector = useSelector((state) => state);

    const SportsMenuItem = ({ id, name, icon, gameicon }) => {
        return (
            <NavLink
                to={`/${role}/${id}/events/All-Games`}
                className={({ isActive }) => (isActive ? 'active' : '')}
            >
                <SubMenu
                    className="highligh-sports border border-light border-5 border-top-0 border-start-0 border-end-0"
                    label={
                        <span className="me-2">
                            <img src={icon} width={30} className="mr-1" alt={`${name} icon`} />
                            {name}
                        </span>
                    }
                >
                    <SubMenu
                        label={
                            <span>
                                <img src={gameicon} width={30} className="mr-1" alt="Game icon" />
                                <span>Games</span>
                            </span>
                        }
                    >
                        <NavLink
                            to={`/${role}/${id}/events/Tournament`}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-trophy me-2"></i>
                                <span>Tournament</span>
                            </MenuItem>
                        </NavLink>
                        <NavLink
                            to={`/${role}/${id}/events/Leagues`}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-people-fill me-2"></i>
                                <span>Leagues</span>
                            </MenuItem>
                        </NavLink>
                        <NavLink
                            to={`/${role}/${id}/events/All-Games`}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-joystick me-2"></i>
                                <span>All Games</span>
                            </MenuItem>
                        </NavLink>
                    </SubMenu>
                    <SubMenu
                        label={
                            <span>
                                <i className="bi bi-geo-alt-fill me-2"></i>
                                Venue
                            </span>
                        }
                    >
                        <NavLink
                            to={`/${role}/${id}/venues`}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-building me-2"></i>
                                <span>Venues</span>
                            </MenuItem>
                        </NavLink>
                        <NavLink
                            to={`/${role}/${id}/slots/AddtimeSlots`}
                            state={{ sportsId: 1 }}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-clock me-2"></i>
                                <span>Time Slots</span>
                            </MenuItem>
                        </NavLink>
                    </SubMenu>

                    <SubMenu
                        label={
                            <span>
                                <i className="bi bi-file-earmark-bar-graph me-2"></i>
                                Report
                            </span>
                        }
                    >
                        <NavLink
                            to={`/organizer/${id}/payment-history`}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-cash-coin me-2"></i>
                                <span>Finance</span>
                            </MenuItem>
                        </NavLink>
                        <NavLink
                            to={`/organizer/${id}/players-payment-history`}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-person-badge-fill me-2"></i>
                                <span>Players Finance</span>
                            </MenuItem>
                        </NavLink>
                        <NavLink
                            to={`/organizer/${id}/reports`}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-graph-up-arrow me-2"></i>
                                <span>Games</span>
                            </MenuItem>
                        </NavLink>
                    </SubMenu>

                    <NavLink
                        to={`/${role}/${id}/members`}
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        <MenuItem>
                            <i className="bi bi-people me-2"></i>
                            <span>Member</span>
                        </MenuItem>
                    </NavLink>
                </SubMenu>
            </NavLink>
        );
    };

    return (
        <div>
            <div
                className="side-nav"
                style={{ display: 'flex', minHeight: '100vh', height: '100vh', maxWidth: '286px' }}
            >
                <Sidebar collapsed={collapsed}>
                    <div className="dashboard-logo">
                        <Link to={`/${role}/dashboard`}>
                            <img src={require("../assets/images/GIU_logo.png")} alt="giu" />
                            <img alt="" src="/assets/images/logo-icon.png" className="small-logo" />
                        </Link>
                    </div>
                    <Menu>
                        <NavLink
                            to="/organizer/dashboard"
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-speedometer2 me-2"></i>
                                <span>Dashboard</span>
                            </MenuItem>
                        </NavLink>

                        <SubMenu
                          
                            label={
                                <span>
                                    <i className="bi-award me-2"></i>
                                    <span>Sports</span>
                                </span>
                            }
                            
                        >
                            {selector.SPORTSLIST.map((item) => (
                                <SportsMenuItem
                                    key={item.id}
                                    id={item.id}
                                    name={item.sports_name}
                                    icon={item.icon}
                                    gameicon={item.game_icon}
                                />
                            ))}
                        </SubMenu>

                        <SubMenu
                            label={
                                <span>
                                    <i className="bi bi-file-earmark-spreadsheet-fill me-2"></i>
                                    Finance Report
                                </span>
                            }
                        >
                            <NavLink
                                to="/organizer/all/payment-history"
                                className={({ isActive }) => (isActive ? 'active' : '')}
                            >
                                <MenuItem>
                                    <i className="bi bi-file-earmark-text me-2"></i>
                                    <span>All Finance Report</span>
                                </MenuItem>
                            </NavLink>
                            <NavLink
                                to="/organizer/my-payouts"
                                className={({ isActive }) => (isActive ? 'active' : '')}
                            >
                                <MenuItem>
                                    <i className="bi bi-receipt me-2"></i>
                                    <span>My Billings</span>
                                </MenuItem>
                            </NavLink>
                        </SubMenu>

                        <NavLink to="#" className={({ isActive }) => (isActive ? 'active' : '')}>
                            <MenuItem disabled>
                                <i className="bi bi-image me-2"></i>
                                <span>Media (Coming soon)</span>
                            </MenuItem>
                        </NavLink>

                        <NavLink to="#" className={({ isActive }) => (isActive ? 'active' : '')}>
                            <MenuItem disabled>
                                <i className="bi bi-person-workspace me-2"></i>
                                <span>Administrative Staff (Coming soon)</span>
                            </MenuItem>
                        </NavLink>

                        <NavLink
                            to={`/${role}/profile-setting`}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <MenuItem>
                                <i className="bi bi-gear-fill me-2"></i>
                                <span>Settings</span>
                            </MenuItem>
                        </NavLink>
                    </Menu>
                    <div className="logout" onClick={() => setShow((prev) => !prev)}>
                        <NavLink to="#" className={({ isActive }) => (isActive ? 'active' : '')}>
                            <i className="bi bi-box-arrow-right me-2"></i>
                            <span>Logout</span>
                        </NavLink>
                    </div>
                </Sidebar>
            </div>
        </div>
    );
};

export { LeftNav };
