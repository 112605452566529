import { Form, Table } from "react-bootstrap";
import { ApiGetAuth } from "../../../helpers/apiCall";
import { useParams } from "react-router";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import NoData from "../../../components/NoData";

function AmountCollected() {
  const { seasonId } = useParams();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedGame, setSelectedGame] = useState('');
  const [selectedSeason, setSelectedSeason] = useState('');


  // Fetch payment history
  const getPaymentHistory = async () => {
    try {
      const success = await ApiGetAuth(`payment/getcollected`);
      setData(success.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPaymentHistory();
  }, [seasonId]);

  // Handle date changes
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  // Handle filter changes
  const handleSportChange = (e) => setSelectedSport(e.target.value);
  const handleGameChange = (e) => setSelectedGame(e.target.value);
  const handleSeasonChange = (e) => setSelectedSeason(e.target.value);

  // Filter data based on selected filters (using a helper function)
  const applyFilters = (dataToFilter) => {
    if (!dataToFilter.length) return [];

    let filtered = dataToFilter.slice(); // Create a copy to avoid modifying original data

    // Apply filters sequentially, skipping if a filter doesn't match
    if (startDate && endDate) {
      const start = moment(startDate, 'YYYY-MM-DD').startOf('day'); // Start of day for startDate
      const end = moment(endDate, 'YYYY-MM-DD').endOf('day');       // End of day for endDate
    
      filtered = filtered.filter((item) => {
        const createdAt = moment(item.created_at);
        return createdAt.isBetween(start, end, null, '[]'); // Inclusive of start and end dates
      });
    }

    if (selectedSport && filtered.length > 0) {
      filtered = filtered.filter(item => item.sports_name == selectedSport);
    }

    if (selectedGame && filtered.length > 0) {
      filtered = filtered.filter(item => item.game_id == selectedGame);
    }

    if (selectedSeason && filtered.length > 0) {
      filtered = filtered.filter(item => item.season_id == selectedSeason);
    }

    return filtered;
  };

  // Trigger filtering when any filter is changed
  useEffect(() => {
    const filtered = applyFilters(data);
    setFilteredData(filtered);
  }, [data, startDate, endDate, selectedSport, selectedGame, selectedSeason]);

  // Memoized function to calculate unique values (sports, games, seasons)
  const uniqueValues = useMemo(() => {
    if (!data.length) return { sports: [], games: [], seasons: [] };

    const filteredBySport = selectedSport ? data.filter(item => item.sports_name == selectedSport) : data;

    const sports = [...new Set(data.map(item => item.sports_name))];
    const games = filteredBySport.reduce((acc, item) => {
      if (!acc.some(game => game.game_id == item.game_id)) {
        acc.push(item);
      }
      return acc;
    }, []).map(item => ({ game_id: item.game_id, game_title: item.game_title })); // Extract relevant game information
    const seasons = filteredBySport.filter(item => (!selectedGame || item.game_id == selectedGame))
      .reduce((acc, item) => {
        if (!acc.some(season => season.season_id == item.season_id)) {
          acc.push(item);
        }
        return acc;
      }, []).map(item => ({ season_id: item.season_id, season_name: item.season_name })); // Extract relevant season information

    return { sports, games, seasons };
  }, [data, selectedSport, selectedGame]);

  return (
    <>
      <div className="dahboard_inner_white_background">
        <div className="row mt-1">
          <div className="col-md-3">
            <Form.Select aria-label="Select Sports" value={selectedSport} onChange={handleSportChange}>
              <option value=''>Select Sports</option>
              {uniqueValues.sports.map(sport => (
                <option key={sport} value={sport}>
                  {sport}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="col-md-3">
            <Form.Select aria-label="Select League/Tournament" value={selectedGame} onChange={handleGameChange}>
              <option value=''>Select League/Tournament</option>
              {uniqueValues.games.map(game => (
                <option key={game.game_id} value={game.game_id}>
                  {game.game_title}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="col-md-3">
            <Form.Select aria-label="Select Season" value={selectedSeason} onChange={handleSeasonChange}>
              <option value=''>Select Season</option>
              {uniqueValues.seasons.map(season => (
                <option key={season.season_id} value={season.season_id}>
                  {season.season_name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="col-md-3">
            <div className="all_games_date_range">
              <div className="input-right-icon">
                <input
                  className="form-control"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <span>To</span>
                <input
                  className="form-control"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
          </div>
        </div>

        {filteredData.length > 0 ? (
          <>
            <Table striped className="w-100 mt-1">
              <thead className="table-primary">
                <tr>
                  <th>Billing id</th>
                  <th>Sports</th>
                  <th>League/Tournament</th>
                  <th>Season</th>
                  <th>Collected Funds</th>
                  <th>Handling Charges</th>
                  <th>Transferred Amount</th>
                  <th>Transfer Date</th>
                  <th>Payment Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row) => (
                  <tr key={row.id}>
                    <td>{row.id}</td>
                    <td>{row.sports_name}</td>
                    <td>{row.game_title}</td>
                    <td>{row.season_name}</td>
                    <td><p className="text-center m-0"> ${Number(row.recieved_funds)?.toFixed(2)}</p></td>
                    <td><p className="text-center m-0"> ${Number(row.transfer_funds - row.amount_to_be_transfer)?.toFixed(2)}</p> </td>
                    <td><p className="text-center m-0"> ${Number(row.amount_to_be_transfer)?.toFixed(2)}</p> </td>
                    <td>{moment(row.created_at)?.format('MMMM Do YYYY')}</td>
                    <td>{row.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <div>
            <NoData
              helpText="No Data Related to the Billings is here Yet!"
              message="As soon as new Billing is Released by Giu Team, it will be available here!"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default AmountCollected;