import axiosInstance from "./axiosInstance"; // Import the axios instance

export const Apipost = (route, data, contentType = "application/x-www-form-urlencoded", authType = false) => {
  return axiosInstance.post(route, data, {
    headers: authType
      ? {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": contentType,
        }
      : {
          "Content-Type": contentType,
        },
  });
};

export const ApiGet = async (route) => {
  try {
    const response = await axiosInstance.get(route);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const ApiGetAuth = async (route) => {
  try {
    const response = await axiosInstance.get(route, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
