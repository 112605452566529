import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ApiGetAuth, Apipost } from "../../helpers/apiCall";
import { useParams } from "react-router";
import { errorMessageNodify, successMessageNodify } from "../../helpers/notify";

const ImportTeamsModal = ({ setRefresh }) => {
  // State variables
  const { sportId, gameId, groupId } = useParams();
  const [show, setShow] = useState(false);
  const [selectedSeasonId, setSelectedSeasonId] = useState(0);
  const [selectedDivisionId, setSelectedDivisionId] = useState(0);
  const [seasons, setSeasons] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
    const [gameType , setGameType] = useState('')

  // Handle team selection
  const handleTeamSelection = (teamId, isSelected) => {
    if (isSelected) {
      setSelectedTeams([...selectedTeams, teamId]);
    } else {
      setSelectedTeams(selectedTeams.filter(id => id !== teamId));
    }
  };

  // Fetch divisions for the selected season
  const handleDivisions = (seasonId) => {
    ApiGetAuth(`season/get-divisions?id=${seasonId}`).then(
      (success) => {
        setDivisions(success.data);
        setSelectedDivisionId(0); // Reset division selection when season changes
        setTeams([]); // Clear teams when season changes
      },
      (err) => {
        console.error('Error fetching divisions:', err);
      }
    );
  };



  // Handle importing selected teams
  const handleImportTeams = () => {
    if(selectedTeams.length && groupId)
    Apipost(
      `/teams/import-teams`,
      { selectedTeams, groupId },
      "application/x-www-form-urlencoded",
      true
    ).then(
      (response) => {
        if (response.data.status === true) {
          handleClose();
          successMessageNodify(response.message);
          setRefresh(prev => !prev);
        } else {
          errorMessageNodify(response.msg);
        }
      },
      (err) => {
        errorMessageNodify(err.msg);
      }
    );
    else{
      errorMessageNodify("Please select teams and group");
    }
  };

  // Fetch available seasons
  const fetchSeasons = async () => {
    try {
      const response = await ApiGetAuth(`season/List-season/${gameId}`);
      setSeasons(response.data.data);
      setGameType(response.data.gametype)
    } catch (error) {
      console.error('Error fetching seasons:', error);
    }
  };

  useEffect(() => {
    if (seasons.length === 0) {
      fetchSeasons();
    }
    if (selectedSeasonId > 0) {
      handleDivisions(selectedSeasonId);
    }
  }, [selectedSeasonId]);


  // Handle modal show and hide
  const handleClose = () => {
    setShow(false);
    // Reset states if needed when closing the modal
    setSelectedSeasonId(0);
    setSelectedDivisionId(0);
    setTeams([]);
    setSelectedTeams([]);
  };

  const handleShow = () => setShow(true);
  return (
    <>
      <button className="btn btn-primary mx-2" onClick={handleShow}>
        Import Teams
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Title><b>Import Teams</b></Modal.Title>

        <Modal.Body>
          {/* Season Selection */}
         <div className="mt-4 mb-4">
         <div className="form-group division-check mb-3">
            <label className="form-label">Select {gameType ==='Tournament'? 'Event':'Season'}</label>
            <select
              className="form-select"
              onChange={(e) => setSelectedSeasonId(Number(e.target.value))}
            >
              <option value={0}>Select {gameType ==='Tournament'? 'Event':'Season'}</option>
              {seasons?.map(item => (
                <option key={item.id} value={item.id}>
                  {item.season_name}
                </option>
              ))}
            </select>
          </div>
         </div>

          {/* Division Selection */}
          <div className="mt-3 mb-5">
          {selectedSeasonId > 0 && (
            <div className="form-group division-check mb-3">
              <label className="form-label">Select Grade/Division</label>
              <select
                className="form-select"
                onChange={(e) => {
                  const selectedDivision = divisions.find(
                    (item) => item.id === Number(e.target.value)
                  );
                  setSelectedDivisionId(selectedDivision?.id || 0);
                  setTeams(selectedDivision?.teams || []);
                  console.log(selectedDivision?.teams, "selectedDivision?.teams")
                  setSelectedTeams(selectedDivision?.teams?.map((item) => item.id))
                }}
              >
                <option value={0}>Select Grade/Division</option>
                {divisions?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.group_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          </div>

          {/* Team Selection */}
          {selectedDivisionId > 0 && (
            <div className="form-group">
              <label className="form-label"> Select Team</label>
              <div className="row division-check">
                {teams?.map(team => (
                   <div className="col-md-4 py-2"  key={team.id}>
                    <div className="">
                      <input
                        className="form-check-input mx-1  custom-checkbox"
                        type="checkbox"
                        id={`team_${team.id}`}
                        checked={selectedTeams.includes(team.id)}
                        onChange={(e) =>
                          handleTeamSelection(team.id, e.target.checked)
                        }
                      />
                      <label className="form-check-label" htmlFor={`team_${team.id}`}>
                        {team.team_name}
                      </label>
                    </div>
                 </div>
                ))}
         </div>
            </div>
          )}

          <div className="d-flex  division-flow mt-3 py-3">
            <button className="btn btn-primary" onClick={handleImportTeams}>
              Import
            </button>
            <button className="btn btn-danger mx-2" onClick={handleClose}>
              Cancel
            </button>
          </div>


        </Modal.Body>


      </Modal>
    </>
  );
};

export default ImportTeamsModal;
